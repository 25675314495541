
import { mdiTunnel } from "@mdi/js";
import { Vue, Component } from "vue-property-decorator";
import draggable from "vuedraggable";

@Component({
  components: {
    draggable
  }
})
export default class Kanban extends Vue {
  // поиск
  search = ""
  performSearch() {
    console.log("Поиск по:", this.search);
  }
  //табы
  activeTab = 'deals';
  tabs = [
    { name: 'deals', label: 'Лиды', content: 'Содержимое вкладки Сделки' },
    { name: 'goods', label: 'Сделки', content: 'Содержимое вкладки Товары и Склады' },
  ]

  icons = {
    mdiTunnel
  }
  editing = false;
  title = 'Заявки'
  startEditing() {
    this.editing = true;
    this.$nextTick(() => {
      const input = this.$refs.titleInput as Vue & { focus: () => void };
      if (input && typeof input.focus === "function") {
        input.focus();
      }
    });
  }
  stopEditing() {
    this.editing = false;
  }


  //   ////////////////////////////////
  newTaskDialog = false;
  newColumnDialog = false;

  categories = ["Mobile", "Web", "Backend"]
  priorities = ["Primary", "Secondary", "Low"]

  newColumn = {
    id: 4,
    title: "",
    color: "",
    x: 100, y: 100,
    expanded: false,
    tasks: []
  }

  task:any = {
    id: 4,
    title: "",
    color: "",
    expanded: false,
    tasks: []
  }

  colors = [
    ["#AAF0D1", "#B0E57C", "#D8BFD8", "#ADD8E6"],
    ["#90EE90", "#FFB6C1", "#FFA07A", "#F5DEB3"],
    ["#E6E6FA", "#F0E68C", "#B0C4DE", "#87CEFA"],
    ["#FF69B4", "#FFDAB9", "#EEE8AA", "#98FB98"],
    ["#AFEEEE", "#DB7093", "#F08080", "#FAFAD2"]
  ];

  array = [
    {
      id: 0,
      title: "Заявки",
      color: "#B0E57C",
      expanded: false,
      x: 100, y: 100,
      tasks: [
        {
          id: 1,
          caption: 'Щербаков Виталий Валентинович', price: "300 000", description: 'Description for task 1', image: '/placeholder.jpg', date: '01.02.2025', status: 'New', comment: 3 },
        { id: 2, caption: 'Task 1', price: "300 000", description: 'Description for task 1', image: 'https://via.placeholder.com/150', date: '11.11.2025', status: 'New', comment: 3 }
      ]
    },
    {
      id: 1,
      title: "Расчеты",
      color: "#F08080",
      expanded: false,
      x: 100, y: 100,
      tasks: [{ id: 3, caption: 'Адилет Мырзабаев Суйунбекович', price: "300 000", description: 'Description for task 1', image: 'https://via.placeholder.com/150', date: '01.02.2025', status: 'In Progress', comment: 3 }]
    },
    {
      id: 2,
      title: "Сделки",
      color: "#90EE90",
      x: 100, y: 100,
      expanded: false,
      tasks: [{ id: 4, caption: 'Task 1', price: "300 000", description: 'Description for task 1', image: 'https://via.placeholder.com/150', date: '01.02.2025', status: 'Done', comment: 3 }]
    }
  ];
  filters = [
    {
      label: "Создать",
      action: () => {
        this.newColumnDialog = true;
      }
    },
    {
      label: "Фильтр",
      icon: "/sort.svg",
      children: [
        { title: 'Продажи' },
        { title: 'Производство' },
        { title: 'Обратная связь' },
        { title: 'Воронки и туннель продаж' },
      ]
    },
  ];

  saveTask(keyString: any) {
    console.log(this.newColumn);
    if (keyString === "column") {
      this.array.push(this.newColumn);
      this.newColumnDialog = false;
      this.newColumn = {
        id: 4,
        title: "",
        color: "",
        expanded: false,
        x: 100, y: 100,
        tasks: []
      }
    }
    console.log("Сохранение задачи:", this.newColumn);

  }

  editTask( item: any) {
    console.log("Редактирование задачи");
  }
  deleteTask(i: number) {
    console.log("Удаление задачи");
  }
  getStatusColor(status) {
    switch (status) {
      case 'New': return '#3d97e4';
      case 'In Progress': return 'orange';
      case 'Done': return '#2eaa42';
      default: return 'grey';
    }
  }

  change($event) {
    console.log("change", $event);
  }
}
